import cx from "classnames";
// import isEmpty from "lodash/isEmpty";
import React from "react";
import { Badge, Button as BaseButton } from "reactstrap";
import WishLamp from "src/assets/images/wish-lamp-logo-2.png";
import Button from "src/components/Button";
import { State } from "src/pages/Play/SelectRegions/Components/RegionList/types";
import { getSeasonInfo, getSeasonObj } from "src/utils/region";
import SeasonBlock from "./SeasonBlock";
import classes from "./styles.module.scss";
import { Props } from "./types";

type TProps = Props & {
  regionName: string;
  regionStates: State[];
  isAllLampFound?: boolean;
  isInactiveRegion?: boolean;
  className?: string;
  stateShowMore?: boolean;
  joinLoading?: boolean;
  season1?: any;
  season2?: any;
  isDisabledView?: boolean;
  seasonGap?: number;
};

const STATES_TO_SHOW = 5;

const RegionContent: React.FC<TProps> = ({
  isJoined = false,
  handleRemoveRegions,
  handleSaveRegion,
  handleViewClues,
  isAllLampFound,
  isInactiveRegion,
  regionName,
  regionStates,
  className,
  stateShowMore,
  joinLoading,
  season1,
  season2,
  isDisabledView,
  seasonGap,
}) => {
  const season1Data = getSeasonInfo(season1);
  const season2Data = getSeasonInfo(season2);
  const getValueText = () => {
    const valueText = regionStates.map((state) => state.name);

    return valueText
      .slice(0, STATES_TO_SHOW)
      .map((i: string) => i || " ")
      .toString()
      .replace(/,/g, ", ");
  };

  const leftStates = regionStates.length - STATES_TO_SHOW;

  const isEitherLampScheduled =
    season1Data?.isScheduled || season2Data?.isScheduled;
  return (
    <>
      <div className={cx(classes.content, className)}>
        <div className={classes.imageWrapper}>
          <img alt="wish-lamp" src={WishLamp} />
        </div>
        <div
          className={cx(classes.badgeWrapper, {
            [classes.found]: isAllLampFound,
            [classes.joined]: isJoined,
          })}
        >
          <Badge
            color="dark"
            className={cx(classes.nameBadge, "region-name-badge")}
          >
            {regionName}
          </Badge>
          <div
            className={cx(classes.states, { [classes.joinedStates]: isJoined })}
          >
            {stateShowMore ? (
              <span>
                {getValueText()}
                {leftStates > 0 ? `...+${leftStates} more` : ""}
              </span>
            ) : (
              regionStates.map(({ name, id }, index) => (
                <span key={id}>
                  {name}
                  {regionStates.length !== index + 1 && ", "}
                </span>
              ))
            )}
          </div>
          <SeasonBlock
            isInactive={isInactiveRegion}
            className={classes.seasonBlock}
            season1={{
              name: "Season 1",
              ...getSeasonObj(season1Data),
            }}
            season2={{
              name: "Season 2",
              ...getSeasonObj(season2Data),
            }}
            seasonGap={seasonGap}
          />
          {isJoined && (
            <>
              <Button
                buttonColor="info"
                buttonClassName={classes.viewClues}
                disabled={
                  isInactiveRegion || isDisabledView || isEitherLampScheduled
                }
                buttonText="View Clues"
                // loading={loading}
                onClick={handleViewClues}
              />
            </>
          )}
          {!isJoined && (
            <Button
              buttonColor="purple"
              buttonClassName={classes.joinRegion}
              buttonText={`Join ${regionName}`}
              onClick={handleSaveRegion}
              loading={joinLoading}
            />
          )}
        </div>

        {isJoined && (
          <div className={classes.joinedRegionSection}>
            {!isAllLampFound && (
              <BaseButton
                onClick={handleRemoveRegions}
                color="link"
                className={classes.previewClues}
              >
                Remove Region
              </BaseButton>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default RegionContent;
