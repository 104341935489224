import moment from "moment";
import Typography from "src/components/Typography";
import { DEFAULT_DATE_FORMAT } from "src/helpers/constants";
import { ClueProps } from "src/pages/Play/Clue/types";
import classes from "./ClueHeader.module.scss";

interface Props {
  clue: ClueProps | null;
}

const ClueHeader = ({ clue }: Props) => {
  return (
    <>
      <div className={classes.clueNumber}>
        <Typography variant="p24">Current: </Typography>
        <Typography fontWeight="semibold" variant="p24">
          {clue?.locked === 1
            ? `Unlocked Clue #${clue?.clue_number}`
            : `Clue #${clue?.clue_number}`}
        </Typography>
      </div>
      <div className={classes.receivedDate}>
        <Typography fontStyle="italic" variant="p14">
          Received: {moment(clue?.publish_date).format(DEFAULT_DATE_FORMAT)}
        </Typography>
      </div>
    </>
  );
};

export default ClueHeader;
