import { useState } from "react";
import Button from "src/components/Button";
import Modal from "src/components/Modal";
import "./modal.styles.scss";

const UnlockedClueModal = () => {
  const [toggle, setToggle] = useState(true);

  const onToggle = () => {
    setToggle(false);
  };

  return (
    <Modal color="primary" isOpen={toggle} toggle={onToggle}>
      <section className="container">
        <h2 className="title">Congratulations on Unlocking a Clue!</h2>
        <p className="description">
          {`Your Treasure Hunting efforts are paying off! 
          Every discovery brings you nearer to the treasure.`}
        </p>
        <Button
          buttonText="Continue to the clue"
          buttonColor="primary"
          onClick={onToggle}
          size="lg"
          textSize="lg"
        />
      </section>
    </Modal>
  );
};

export default UnlockedClueModal;
