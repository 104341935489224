import Cookie from "js-cookie";
import get from "lodash/get";
import { Dispatch } from "redux";
import {
  AddPaymentProps,
  ForgotPasswordProps,
  LoginUser,
  PaymentDetailsProps,
  ResetPasswordProps,
  SSOSignUpData,
  UpdateUserProfileProps,
  User,
  UserParams,
  addPaymentMethods as addPaymentMethodsApi,
  apiDeleteAccount,
  forgotPassword as forgotPasswordApi,
  getPaymentIntent as getPaymentIntentApi,
  getPaymentMethods as getPaymentMethodsApi,
  getSubscriptionDetails as getSubscriptionDetailsApi,
  getSubscriptionPlans as getSubscriptionPlansApi,
  getUserProfile as getUserProfileApi,
  getUserSettings as getUserSettingsApi,
  postLogin as postLoginApi,
  postPaymentDetails as postPaymentDetailsApi,
  postSignup as postSignupApi,
  postUserExistQuery as postUserExistQueryApi,
  removePaymentMethod as removePaymentMethodApi,
  resetPassword as resetPasswordApi,
  ssoLogin as ssoLoginApi,
  ssoSignUp as ssoSignUpApi,
  unsubscribePlan as unsubscribePlanApi,
  updateUserProfile as updateUserProfileApi,
  updateUserSettings as updateUserSettingsApi,
  verifyPayment as verifyPaymentApi,
} from "src/api/auth";
import { ROLE_NAME } from "src/helpers/constants/role";

import { SignupDetailsProps } from "src/pages/Auth/types";
import { PlanProps } from "src/pages/Subscription/Plans/Components/PlanCard/types";
import Types from "../types/auth";

export const syncSetLogin = (value: boolean) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: Types.SYNC_UPDATE_LOGIN,
      payload: value,
    });
  };
};
export const logoutUser = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: Types.USER_LOGOUT_SUCCESS,
    });
    Cookie.remove("token");
  };
};

export const doSignUp = (
  data: User,
  cb?: (resp: any) => void,
  cbError?: (error: any) => void
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: Types.USER_SIGNUP_STARTED,
    });

    const resp = await postSignupApi(data);

    if (resp?.status) {
      dispatch({
        type: Types.USER_SIGNUP_SUCCESS,
        payload: { data: resp.data.user },
      });
      const token = get(resp, "data.token", "");
      Cookie.set("token", token);
      await getUserProfile()(dispatch);
      if (cb) {
        cb(resp);
      }
    } else {
      const errorMessage =
        typeof resp?.data?.message === "object"
          ? Object.values(resp?.data?.message).flat().join(", ")
          : resp?.data?.message;
      dispatch({
        type: Types.USER_SIGNUP_FAILED,
        payload: {
          message: errorMessage,
        },
      });
      if (cbError) cbError({ message: errorMessage });
    }
    return resp;
  };
};

export const resetPassword =
  (data: ResetPasswordProps, cb?: any) => (dispatch: Dispatch) => {
    return dispatch({
      isAsyncCall: true,
      payload: {},
      type: Types.RESET_PASSWORD,
      asyncCall: async () => {
        const resp = await resetPasswordApi(data);
        const token = get(resp, "data.token", "");
        Cookie.set("token", token);
        return resp;
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };

export const getUserProfile = (cb?: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: Types.FETCH_USER_PROFILE_STARTED,
    });

    const resp = await getUserProfileApi();
    const is_tg_account = get(resp, "data.is_tg_account", false);
    if (is_tg_account) {
      resp.data.user.role.name = ROLE_NAME.ADMIN;
    }

    if (resp?.status) {
      dispatch({
        type: Types.FETCH_USER_PROFILE_SUCCESS,
        payload: { data: resp.data },
      });
      if (cb) cb(resp);
    } else {
      dispatch({
        type: Types.FETCH_USER_PROFILE_FAILED,
        payload: {
          message: resp?.message,
        },
      });
      if (cb) cb(false);
    }
    return resp;
  };
};

export const updateUserProfile = (data: FormData, cb?: any) => {
  //UserProfileUpdateProps
  return async (dispatch: Dispatch) => {
    dispatch({
      type: Types.UPDATE_USER_PROFILE_STARTED,
    });

    const resp = await updateUserProfileApi(data);

    if (resp?.status) {
      dispatch({
        type: Types.UPDATE_USER_PROFILE_SUCCESS,
        payload: { data: resp.data },
      });
      if (cb) cb(resp?.status);
    } else {
      dispatch({
        type: Types.UPDATE_USER_PROFILE_FAILED,
        payload: {
          message: resp?.message,
        },
      });
    }
    return resp;
  };
};

export const getSubscriptionDetails = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: Types.FETCH_USER_SUBSCRIPTION_DETAILS_STARTED,
    });

    const resp = await getSubscriptionDetailsApi();

    if (resp?.status) {
      dispatch({
        type: Types.FETCH_USER_SUBSCRIPTION_DETAILS_SUCCESS,
        payload: { data: resp.data },
      });
    } else {
      dispatch({
        type: Types.FETCH_USER_SUBSCRIPTION_DETAILS_FAILED,
        payload: {
          message: resp?.message,
        },
      });
    }
    return resp;
  };
};

export const getPaymentMethods = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: Types.GET_PAYMENT_METHODS_STARTED,
    });

    const resp = await getPaymentMethodsApi();
    const payment_methods = get(resp, "data.payment_methods", []);

    if (resp?.status) {
      dispatch({
        type: Types.GET_PAYMENT_METHODS_SUCCESS,
        payload: { data: payment_methods },
      });
    } else {
      dispatch({
        type: Types.GET_PAYMENT_METHODS_FAILED,
        payload: {
          message: resp?.message,
        },
      });
    }
    return resp;
  };
};

export const postPaymentDetails = (
  data: PaymentDetailsProps,
  last4?: string,
  cb?: any
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: Types.USER_PAYMENT_DETAILS_STARTED,
    });

    const resp = await postPaymentDetailsApi(data);

    if (resp?.status) {
      dispatch({
        type: Types.USER_PAYMENT_DETAILS_SUCCESS,
        payload: { data: { ...resp.data, last4 } },
      });
      if (cb) cb(resp);
    } else {
      dispatch({
        type: Types.USER_PAYMENT_DETAILS_FAILED,
        payload: {
          message: resp?.message,
        },
      });
    }
    return resp;
  };
};

export const removePaymentMethod =
  (id: number, cb?: any) => (dispatch: Dispatch) => {
    dispatch({
      isAsyncCall: true,
      payload: { id },
      type: Types.REMOVE_PAYMENT_METHOD,
      asyncCall: () => {
        return removePaymentMethodApi(id);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };

export const addPaymentMethods = (data: AddPaymentProps) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: Types.ADD_PAYMENT_METHODS_STARTED,
    });

    const resp = await addPaymentMethodsApi(data);

    const payment_method = get(resp, "data.payment_method", {});

    if (resp?.status) {
      dispatch({
        type: Types.ADD_PAYMENT_METHODS_SUCCESS,
        payload: { data: { payment_method } },
      });
    } else {
      dispatch({
        type: Types.ADD_PAYMENT_METHODS_FAILED,
        payload: {
          message: resp?.message,
        },
      });
    }
    return resp;
  };
};

export const doLogin = (data: LoginUser, cbAfterGetProfile?: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: Types.USER_LOGIN_STARTED,
    });

    const resp = await postLoginApi(data);
    if (resp.status) {
      dispatch({
        type: Types.USER_LOGIN_SUCCESS,
        payload: { data: resp.data },
      });
      const token = get(resp, "data.token", "");
      Cookie.set("token", token);
      await getUserProfile(cbAfterGetProfile)(dispatch);
    } else {
      dispatch({
        type: Types.USER_LOGIN_FAILED,
        payload: {
          message: resp.message,
        },
      });
    }
    return resp;
  };
};

export const addSignupDetails = (data: SignupDetailsProps) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: Types.SET_USER_SIGNUP_DETAILS,
      payload: { data: data },
    });
  };
};

export const removeSignupDetails = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: Types.REMOVE_USER_SIGNUP_DETAILS,
    });
  };
};

export const getSubscriptionPlans = (cb?: (res: any) => void) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: Types.GET_SUBSCRIPTION_PLANS_STARTED,
    });
    const resp = await getSubscriptionPlansApi();
    if (resp?.status === 1) {
      dispatch({
        type: Types.GET_SUBSCRIPTION_PLANS_SUCCESS,
        payload: { data: resp.data },
      });
      if (cb) {
        cb(resp);
      }
    } else {
      dispatch({
        type: Types.GET_SUBSCRIPTION_PLANS_FAILED,
        payload: {
          message: resp?.message,
        },
      });
    }
    return resp;
  };
};

export const setSelectedPlan = (planDetails: PlanProps) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: Types.SET_SELECTED_PLAN_DETAILS,
      payload: planDetails,
    });
  };
};

export const postUserExistQuery = (
  data: UserParams,
  cb?: (res: any) => void
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: Types.USER_EXIST_CHECK_STARTED,
    });

    const resp = await postUserExistQueryApi(data);
    if (resp) {
      dispatch({
        type: Types.USER_EXIST_CHECK__SUCCESS,
        payload: { data: resp.message },
      });
      if (cb) {
        cb(resp);
      }
    } else {
      dispatch({
        type: Types.USER_EXIST_CHECK_FAILED,
        payload: {
          message: resp?.message,
        },
      });
    }
    return resp;
  };
};

export const setUserSubscriptionFlow = (fromSignUp = true) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: Types.IS_USER_SUBSCRIPTION_FLOW_FROM_SIGNUP,
      payload: { data: fromSignUp },
    });
  };
};

export const updateUserSettings =
  (data: UpdateUserProfileProps, cb?: any) => (dispatch: Dispatch) => {
    return dispatch({
      isAsyncCall: true,
      payload: {},
      type: Types.USER_SETTINGS_UPDATE,
      asyncCall: () => {
        return updateUserSettingsApi(data);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };

export const getUserSettings = (cb?: any) => (dispatch: Dispatch) => {
  dispatch({
    isAsyncCall: true,
    payload: {},
    type: Types.GET_SETTINGS,
    asyncCall: () => {
      return getUserSettingsApi();
    },
    onSuccess: (_dispatch: any, response: any) => {
      if (cb) cb(response);
    },
    onFailure: (_dispatch: any, error: any) => {
      if (cb) cb(false, error);
    },
  });
};

export const unsubscribePlan = (cb?: any) => (dispatch: Dispatch) => {
  dispatch({
    isAsyncCall: true,
    payload: {},
    type: Types.UNSUBSCRIBE_PLAN,
    asyncCall: () => {
      return unsubscribePlanApi();
    },
    onSuccess: (_dispatch: any, response: any) => {
      if (cb) cb(response);
    },
    onFailure: (_dispatch: any, error: any) => {
      if (cb) cb(false, error);
    },
  });
};

export const forgotPassword =
  (data: ForgotPasswordProps, cb?: any) => (dispatch: Dispatch) => {
    return dispatch({
      isAsyncCall: true,
      payload: {},
      type: Types.FORGOT_PASSWORD,
      asyncCall: () => {
        return forgotPasswordApi(data);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };

export const deleteAccount = (cb?: any) => (dispatch: Dispatch) => {
  return dispatch({
    isAsyncCall: true,
    payload: {},
    type: Types.DELETE_ACCOUNT,
    asyncCall: () => {
      return apiDeleteAccount();
    },
    onSuccess: (_dispatch: any, response: any) => {
      if (cb) cb(response);
    },
    onFailure: (_dispatch: any, error: any) => {
      if (cb) cb(false, error);
    },
  });
};

export const ssoSignUp =
  (data: SSOSignUpData, cb?: any) => (dispatch: Dispatch) => {
    return dispatch({
      isAsyncCall: true,
      payload: {},
      type: Types.SSO_SIGNUP,
      asyncCall: () => {
        return ssoSignUpApi(data);
      },
      onSuccess: async (_dispatch: any, response: any) => {
        if (response.status) {
          const token = get(response, "data.token", "");
          Cookie.set("token", token);
          await getUserProfile(cb)(_dispatch);
        }
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(error, false);
      },
    });
  };

export const setSsoData = (data: Partial<SSOSignUpData>) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.SET_SSO_DATA,
      payload: data,
    });
  };
};

export const getPaymentIntent =
  (subscriptionId: string, cb?: any) => (dispatch: Dispatch) => {
    dispatch({
      isAsyncCall: true,
      payload: {},
      type: Types.GET_PAYMENT_INTENT,
      asyncCall: () => {
        return getPaymentIntentApi(subscriptionId);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };

export const verifyPayment =
  (subscriptionId: string, cb?: any) => (dispatch: Dispatch) => {
    dispatch({
      isAsyncCall: true,
      payload: {},
      type: Types.VERIFY_PAYMENT,
      asyncCall: () => {
        return verifyPaymentApi(subscriptionId);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response, true);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(error, false);
      },
    });
  };

export const ssoLogin =
  (data: SSOSignUpData, cb?: any) => (dispatch: Dispatch) => {
    dispatch({
      isAsyncCall: true,
      payload: {},
      type: Types.SSO_LOGIN,
      asyncCall: () => {
        return ssoLoginApi(data);
      },
      onSuccess: async (_dispatch: any, response: any) => {
        if (response.status) {
          const token = get(response, "data.token", "");
          Cookie.set("token", token);
          await getUserProfile(cb)(_dispatch);
        }
      },
      onFailure: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
    });
  };
