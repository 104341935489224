import {
  faCircleCheck,
  faLightbulb,
} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import get from "lodash/get";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { HintProps } from "src/api/clue";
import { ReactComponent as HintBulb } from "src/assets/icons/hint-bulb-icon.svg";
import Button from "src/components/Button";
import Card from "src/components/Card";
import Text from "src/components/Text";
import { useIsUserProfileFreeTrial } from "src/helpers/hooks/useUserProfile";
import { ClueProps } from "src/pages/Play/Clue/types";

import {
  editClueStatus,
  editCluesAnswer,
  getClues,
  getUnlockedClues,
  interactiveWordPuzzleUpdate,
  postCluesAnswer,
  setCluePurchaseModalState,
} from "src/store/actions/clue";
import { useReducerData, useStoreActions } from "src/store/hooks";
import { useRegionData } from "src/utils/useData";
import { checkAllFreeCluesCompleted } from "src/utils/utils";
import classes from "./InteractiveInputBox.module.scss";

type Props = {
  currentClue?: ClueProps;
  clues: ClueProps[];
};

const SHOW_PURCHASE_HINTS = false;

const InteractiveInputBox: React.FC<Props> = ({ currentClue }) => {
  const [isCompleteClue, setIsCompleteClue] = useState(false);
  const actionClues = useStoreActions({ getUnlockedClues, getClues });
  const actions = useStoreActions({
    postCluesAnswer,
    interactiveWordPuzzleUpdate,
    setCluePurchaseModalState,
    editClueStatus,
    editCluesAnswer,
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const isFreeTrial = useIsUserProfileFreeTrial();
  const { answer } = useReducerData("clue", "interactiveWordPuzzleState", {
    answer: "",
    error: "",
  });
  const { data } = useReducerData("clue", "purchaseHintList", {
    data: [],
  });

  const { hints }: { hints: HintProps[] } = data;

  const hint = hints?.find((h) => h?.hint_number === 1);

  const isCompleted = currentClue?.answers?.some(
    (ans) => ans?.answer?.is_correct === 1
  );
  const previousAnswer = currentClue?.answers[0]?.answer;

  const WORD = currentClue?.custom_text?.visible_text || "***e*e***";
  const regionId = searchParams.get("region") || "region-1";
  const season = searchParams.get("season") || "1";

  const clue_difficulty = Number(currentClue?.clue_difficulty || "0");
  const [loading, setLoading] = useState(false);
  const { region: regionData } = useRegionData();
  const { seasons = [] } = regionData.data;
  const currentSeason = searchParams.get("season") || "1";

  const selectedSeason = seasons.find(
    (season) => season?.id?.toString() === currentSeason
  );
  const lampId = selectedSeason?.lamp?.id ?? 1;

  useEffect(() => {
    const onRefreshClues = async () => {
      if (isCompleteClue) {
        if (currentClue?.locked === 1) {
          actionClues.getUnlockedClues(lampId);
        } else {
          actionClues.getClues(regionId, { season });
        }
      }
    };

    onRefreshClues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCompleteClue]);

  const callback = (updatedClues: ClueProps[]) => {
    if (isFreeTrial) {
      const isAllFreeCluesCompleted = checkAllFreeCluesCompleted(
        updatedClues || []
      );
      if (isAllFreeCluesCompleted) {
        searchParams.set("show_upgrade_modal", "true");
        searchParams.set("all_clues_completed", "true");
        setSearchParams(searchParams);
      }
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    let res = null;
    if (!previousAnswer?.answer) {
      res = await actions.postCluesAnswer(
        currentClue?.id,
        answer,
        {
          answer_number: 1,
        },

        callback,
        currentClue?.locked === 1 ? true : false
      );
    } else {
      res = await actions.editCluesAnswer(
        currentClue?.id,
        previousAnswer.id,
        answer,

        {
          answer_number: 1,
        },

        callback,
        currentClue?.locked === 1 ? true : false
      );
    }
    const status: boolean = get(res, "data.is_correct_answer", false);

    if (status) {
      setIsCompleteClue(true);
    }

    actions.interactiveWordPuzzleUpdate(
      answer,
      !status ? "Incorrect word. Try again." : ""
    );

    setLoading(false);
  };

  return (
    <Card className={classes.inputs}>
      <div className={classes.buttonsWrapper}>
        {!isCompleted &&
          SHOW_PURCHASE_HINTS &&
          clue_difficulty > 3 &&
          (hint?.hint ? (
            <>
              <div className={classes.hintSection}>
                <div className={classes.label}>
                  <Text
                    className={classes.text}
                    size={20}
                    fontWeight="medium"
                    color="#8053EA"
                  >
                    <HintBulb /> Hint:{" "}
                    <span className={classes.hint}>{hint?.hint}</span>
                  </Text>
                </div>
              </div>
            </>
          ) : (
            <Button
              buttonText={
                <Text
                  color="#8053EA"
                  size={20}
                  fontFamily="inter"
                  fontWeight="medium"
                >
                  Purchase a hint
                </Text>
              }
              variant="outline"
              // loading={loading && clueId === currentId}
              onClick={() => actions.setCluePurchaseModalState(true, "1")}
              rightIcon={<FontAwesomeIcon icon={faLightbulb} />}
              buttonClassName={classes.purchase}
            ></Button>
          ))}
        {isCompleted ? (
          <div>
            <Text color="#5AA332" size={24} fontWeight="bold">
              <FontAwesomeIcon icon={faCircleCheck} /> Solved
            </Text>
          </div>
        ) : (
          <Button
            buttonText={
              <Text color="#fff" size={24} fontFamily="inter" fontWeight="bold">
                Submit
              </Text>
            }
            type="submit"
            disabled={answer.length !== WORD.length}
            loading={loading}
            onClick={handleSubmit}
            className={classes.submit}
          />
        )}
      </div>
    </Card>
  );
};

export default InteractiveInputBox;
