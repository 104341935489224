import Cookie from "js-cookie";
import Echo from "laravel-echo";
import get from "lodash/get";
import socketIoClient from "socket.io-client";

class Socket {
  echo: any;

  start(meData: unknown) {
    // stop earlier socket connection if there is any before starting new socket connection
    this.stop();
    const accessToken = `${Cookie.get("token")}`;
    const userId = Number(get(meData, "data.user.id"));
    if (!accessToken) {
      return false;
    }
    const authToken = `Bearer ${accessToken}`;
    const echoHost = process.env.REACT_APP_API_BASE_URL;

    this.echo = new Echo({
      auth: {
        headers: {
          Authorization: authToken,
        },
      },
      broadcaster: "socket.io",
      host: echoHost,
      client: socketIoClient,
    });
    this.listenNotification(userId);
  }

  listenNotification(userId: number) {
    this.echo.private(`web-alerts.${userId}`);
  }

  stop() {
    // this method used to unregister all subscription on socket
    if (this.echo) {
      this.echo.disconnect();
    }
  }
}

export default new Socket();
