import { faUpRightAndDownLeftFromCenter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";
import get from "lodash/get";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import Card from "src/components/Card";
import Typography from "src/components/Typography";
import { ROLE_LEVEL } from "src/helpers/constants/role";
import useUserProfile from "src/helpers/hooks/useUserProfile";
import ClueGame from "src/pages/Play/Clue/Components/ClueDashboard/Clues/ClueListView/ClueGame";
import GameMatching from "src/pages/Play/Clue/Components/ClueDashboard/Clues/ClueListView/ClueGame/Matching";
import GameSnake from "src/pages/Play/Clue/Components/ClueDashboard/Clues/ClueListView/ClueGame/Snake";
import GameTicToe from "src/pages/Play/Clue/Components/ClueDashboard/Clues/ClueListView/ClueGame/TicToe";
import { GAME } from "src/pages/Play/Clue/Components/ClueDashboard/Clues/ClueListView/constant";
import { getClueGameInfo } from "src/pages/Play/Clue/Components/ClueDashboard/Clues/ClueListView/utils";
import { ClueProps } from "src/pages/Play/Clue/types";
import { getPaymentMethods } from "src/store/actions/auth";
import {
  purchaseHintList,
  setClueModalState,
  setCluePurchaseModalState,
} from "src/store/actions/clue";
import { answerCustomClue } from "src/store/actions/customGame";
import { useReducerData, useStoreActions } from "src/store/hooks";
import { useClueActions } from "src/utils/useActions";
import { useCluesData } from "src/utils/useData";
import { checkAllFreeCluesCompleted } from "src/utils/utils";
import BaseInputBox from "./InputBoxes/BaseInputBox";
import InteractiveInputBox from "./InputBoxes/InteractiveInputBox";
import PurchaseModal from "./PurchaseModal";
import AudioQuestion from "./QuestionMedia/AudioQuestion";
import ImageQuestion from "./QuestionMedia/ImageQuestion";
import InteractiveWordPuzzleQuestion from "./QuestionMedia/InteractiveWordPuzzleQuestion";
import PuzzleQuestion from "./QuestionMedia/PuzzleQuestion";
import RiddleQuestion from "./QuestionMedia/RiddleQuestion";
import TextFieldQuestion from "./QuestionMedia/TextFieldQuestion";
import TextImageQuestion from "./QuestionMedia/TextImageQuestion";
import UrlQuestion from "./QuestionMedia/UrlQuestion";
import VideoQuestion from "./QuestionMedia/VideoQuestion";
import WordSearchQuestion from "./QuestionMedia/WordSearchQuestion";

import FollowUpInstructions from "./followUpInstructions";
import classes from "./styles.module.scss";

type QuestionProps = {
  isModal?: boolean;
  clue: ClueProps | null;
};

const Question = ({ isModal, clue }: QuestionProps) => {
  const actions = useStoreActions({
    setClueModalState,
    setCluePurchaseModalState,
    purchaseHintList,
    getPaymentMethods,
    answerCustomClue,
  });
  const { getClues } = useClueActions();
  const {
    getUnlockedCluesData: { data: unlockedClueData },
  } = useCluesData();
  const [searchParams, setSearchParams] = useSearchParams();
  const regionId = searchParams.get("region") || "region-1";
  const season = searchParams.get("season") || "1";

  const { data: clues }: { data: ClueProps[] } = useReducerData(
    "clue",
    "clues",
    {
      loading: false,
      data: [],
    }
  );

  const { isOpen, index } = useReducerData("clue", "purchaseModal", {
    isOpen: false,
    index: null,
  });
  const userProfile = useUserProfile();
  const isFreeTrial = userProfile.type === ROLE_LEVEL.FREE_TRIAL;

  const currentClue = clue;
  const { displayGame, gameDescription } = getClueGameInfo(currentClue);
  const currentClueAnswer = currentClue?.answers || [];
  const currentAnswer = currentClueAnswer
    .map((a) => get(a, "answer"))
    .filter(
      (c) =>
        get(c, "clue_id") === currentClue?.id &&
        get(c, "user_id") === userProfile.user?.id
    )?.[0];
  const currentGameAnswer = get(currentAnswer, "answer");
  const isCompleted =
    currentClue?.status === "complete" ||
    currentClue?.answer_status === "complete";
  const followUpInstructions = currentClue?.follow_up_instructions || "";

  const handleGameSuccess = () => {
    actions.answerCustomClue(currentClue?.id, (res: any) => {
      const answerBlock: any = document.querySelector("#custom-game-answer");
      if (answerBlock) {
        answerBlock.classList.remove("getting");
        answerBlock.innerHTML = res.data?.answer;
      }
      const updatedClues: any = getClues(`${regionId}`, { season });

      if (isFreeTrial) {
        const isAllFreeCluesCompleted = checkAllFreeCluesCompleted(
          (updatedClues?.data?.clues || []) as ClueProps[]
        );
        if (isAllFreeCluesCompleted) {
          searchParams.set("show_upgrade_modal", "true");
          searchParams.set("all_clues_completed", "true");
          setSearchParams(searchParams);
        }
      }
    });
  };

  useEffect(() => {
    if (!isFreeTrial && currentClue) {
      actions.purchaseHintList(currentClue?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClue]);

  useEffect(() => {
    if (isOpen) {
      actions.getPaymentMethods();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  // TODO: we need to design all the components as we get the design for each type of questions
  const getQuestion = () => {
    if (displayGame) {
      return (
        <ClueGame
          clue={currentClue || null}
          gameDescription={gameDescription}
          maxScore={30}
          gameName={displayGame}
          answer={currentGameAnswer}
          hideHeader
          hideModal
        >
          {displayGame === GAME.SNAKE.NAME ? (
            <GameSnake
              onSuccess={handleGameSuccess}
              answer={currentGameAnswer}
            />
          ) : displayGame === GAME.MATCHING.NAME ? (
            <GameMatching
              onSuccess={handleGameSuccess}
              answer={currentGameAnswer}
            />
          ) : (
            <GameTicToe
              onSuccess={handleGameSuccess}
              answer={currentGameAnswer}
            />
          )}
        </ClueGame>
      );
    }
    switch (currentClue?.field_type?.slug) {
      case "image":
      case "rebus":
      case "image-upload":
      case "custom-image-upload":
        return <ImageQuestion clue={currentClue} isModal={isModal} />;
      case "video":
      case "video-upload":
        return <VideoQuestion clue={currentClue} isModal={isModal} />;
      case "audio":
      case "audio-file-upload":
        return <AudioQuestion clue={currentClue} isModal={isModal} />;
      case "riddle":
        return <RiddleQuestion clue={currentClue} isModal={isModal} />;
      case "word_search":
        return <WordSearchQuestion clue={currentClue} isModal={isModal} />;
      case "puzzle":
        return <PuzzleQuestion clue={currentClue} isModal={isModal} />;
      case "url-link":
        return <UrlQuestion clue={currentClue} isModal={isModal} />;
      case "custom":
        return (
          <InteractiveWordPuzzleQuestion clue={currentClue} isModal={isModal} />
        );
      case "text-image":
        return <TextImageQuestion clue={currentClue} isModal={isModal} />;
      case "text-field":
        return <TextFieldQuestion clue={currentClue} isModal={isModal} />;
      case "hidden-images":
        return <Typography>here</Typography>;
      default:
        return <div></div>;
    }
  };

  const getInputBoxes = () => {
    switch (currentClue?.field_type?.slug) {
      case "custom":
        return (
          <InteractiveInputBox
            currentClue={currentClue}
            clues={currentClue?.locked === 1 ? unlockedClueData : clues}
          />
        );
      default:
        return (
          <BaseInputBox
            currentClue={currentClue}
            clues={currentClue?.locked === 1 ? unlockedClueData : clues}
          />
        );
    }
  };

  return (
    <>
      {currentClue && isOpen && (
        <PurchaseModal
          clue={currentClue}
          isOpen={isOpen}
          answerIndex={index}
          onClose={() => actions.setCluePurchaseModalState(false)}
        />
      )}
      <div className={classes.questionsWrapper}>
        <Card
          className={cx(classes.question, {
            [classes.isModal]: isModal,
          })}
          cardBodyClassName={cx(classes.cardBody, {
            [classes.isModal]: isModal,
          })}
        >
          {!!followUpInstructions && isCompleted && (
            <FollowUpInstructions instructions={followUpInstructions} />
          )}
          {!isModal && (
            <div className={classes.header}>
              <div className={classes.description}>
                <Typography variant="p16">
                  {currentClue?.description}
                </Typography>
              </div>
            </div>
          )}
          <div
            className={cx(classes.questionContent, {
              [classes.modalContent]: isModal,
            })}
          >
            {getQuestion()}
            {!isModal && (
              <div
                onClick={() => {
                  actions.setClueModalState(true, currentClue as ClueProps);
                }}
                className={classes.arrow}
              >
                <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} />
              </div>
            )}
          </div>
        </Card>
        {!displayGame && <>{getInputBoxes()}</>}
      </div>
    </>
  );
};

export default Question;
