import get from "lodash/get";
import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import WishLampLogo from "src/assets/images/wish-lamp-landing.png";
import Button from "src/components/Button";
import Spinner from "src/components/Spinner";
import Text from "src/components/Text";
import { ANALYTICS_CONSTANTS } from "src/helpers/constants/analytics";
import { ROLE_LEVEL } from "src/helpers/constants/role";
import useUserProfile from "src/helpers/hooks/useUserProfile";
import { ClueProps } from "src/pages/Play/Clue/types";
import { getClueStatus, getCurrentClue } from "src/pages/Play/Clue/utils";
import { editClueStatus, setClueData } from "src/store/actions/clue";
import { answerCustomClue } from "src/store/actions/customGame";
import { useReducerData, useStoreActions } from "src/store/hooks";
import { trackEvent } from "src/utils/googleAnalytics";
import { useClueActions } from "src/utils/useActions";
import { useCluesData } from "src/utils/useData";
import { isClueCompleted } from "src/utils/utils";
import ClueGame from "./ClueGame";
import GameMatching from "./ClueGame/Matching";
import GameSnake from "./ClueGame/Snake";
import GameTicToe from "./ClueGame/TicToe";
import ClueList from "./ClueList";
import ClueQuestion from "./ClueQuestion";
import { GAME } from "./constant";
import classes from "./styles.module.scss";
import { getClueGameInfo } from "./utils";

type Props = {
  allFound: boolean;
  clues: ClueProps[];
  tab?: string;
};

const ClueListView: React.FC<Props> = ({ allFound, clues, tab }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const regionId = searchParams.get("region") || "1";
  const season = searchParams.get("season") || "1";
  const actions = useStoreActions({
    setClueData,
    editClueStatus,
    answerCustomClue,
  });
  const { getClues } = useClueActions();

  const {
    clues: { data: allClues, loading },
  } = useCluesData();
  const { loading: statusUpdateLoading }: { loading: boolean } = useReducerData(
    "clue",
    "editClueStatus",
    {
      loading: false,
    }
  );

  const userProfile = useUserProfile();
  const isFreeTrial = userProfile.type === ROLE_LEVEL.FREE_TRIAL;
  const currentClue = getCurrentClue(clues);
  const currentClueAnswer = currentClue?.answers || [];
  const currentAnswer = currentClueAnswer
    .map((a) => get(a, "answer"))
    .filter(
      (c) =>
        get(c, "clue_id") === currentClue?.id &&
        get(c, "user_id") === userProfile.user?.id
    )?.[0];
  const currentGameAnswer = get(currentAnswer, "answer");
  const [currentClueId, setCurrentClueId] = useState<number | null>(null);
  const { displayGame, gameDescription } = getClueGameInfo(currentClue);

  const handleClueStatusChange = async (selectedClue: ClueProps) => {
    const updatedStatus = [
      {
        id: selectedClue.id,
        is_current: true,
        status: getClueStatus(selectedClue.answer_status),
        locked: selectedClue.locked,
      },
    ];

    await actions.editClueStatus(
      updatedStatus,
      selectedClue.locked === 1 ? true : false
    );
  };

  const handleSelectClue = async (clue: ClueProps | null) => {
    if (!clue) return;
    const firstClueIndex = 0;
    const clueIndex = clues.findIndex((c) => c.id === clue.id);

    const lastClueIndex = clues?.length;
    if (firstClueIndex - 1 < clueIndex && lastClueIndex + 1 > clueIndex) {
      const getCurrentClue = clues.find((c) => c?.id === clue.id) || clues[0];
      await handleClueStatusChange(getCurrentClue);
      setCurrentClueId(clue.id);
    }
  };

  const handleGameSuccess = () => {
    actions.answerCustomClue(currentClue?.id, (res: any) => {
      const answerBlock: any = document.querySelector("#custom-game-answer");
      if (answerBlock) {
        answerBlock.classList.remove("getting");
        answerBlock.innerHTML = res.data?.answer;
      }
      getClues(`${regionId}`, { season });
    });
  };

  const notCompletedClues = allClues.filter(
    (clue) => !isClueCompleted(clue) && clue.answer_status !== "locked"
  );

  const renderClueContent = () => {
    switch (true) {
      case isFreeTrial &&
        notCompletedClues.length === 0 &&
        tab === "not-completed-clues":
        return (
          <div className={classes.freeCompleted}>
            <div className={classes.icon}>
              <img src={WishLampLogo} alt="logo" />
            </div>
            <div className={classes.completedText}>
              Congratulations, you’ve solved all the Free Trial Clues for this
              region
            </div>
            <div className={classes.message}>
              Upgrade to <span>Full Access</span> to unlock all clues and be
              eligible to win weekly prizes and the grand prize if you find the
              lamp!
            </div>
            <div>
              <Button
                buttonText="Upgrade to Full Access"
                buttonColor="purple-gradient"
                buttonClassName={classes.upgradeButton}
                onClick={() => {
                  searchParams.set("show_upgrade_modal", "true");
                  setSearchParams(searchParams);
                  trackEvent(
                    ANALYTICS_CONSTANTS.category.sign_up,
                    ANALYTICS_CONSTANTS.action.convert_free_to_full
                  );
                }}
              />
            </div>
          </div>
        );
      case !!currentClue:
        return (
          <>
            {displayGame ? (
              <>
                <ClueGame
                  clue={currentClue || null}
                  gameDescription={gameDescription}
                  maxScore={30}
                  gameName={displayGame}
                  answer={currentGameAnswer}
                  disabled={allFound}
                >
                  {displayGame === GAME.SNAKE.NAME ? (
                    <GameSnake
                      onSuccess={handleGameSuccess}
                      answer={currentGameAnswer}
                      disabled={allFound}
                    />
                  ) : displayGame === GAME.MATCHING.NAME ? (
                    <GameMatching
                      onSuccess={handleGameSuccess}
                      answer={currentGameAnswer}
                      disabled={allFound}
                    />
                  ) : (
                    <GameTicToe
                      onSuccess={handleGameSuccess}
                      answer={currentGameAnswer}
                      disabled={allFound}
                    />
                  )}
                </ClueGame>
              </>
            ) : (
              <ClueQuestion clue={currentClue || null} allFound={allFound} />
            )}
          </>
        );
      default:
        return (
          <div className="mt-4">
            {loading || statusUpdateLoading ? (
              <Spinner />
            ) : (
              <Text size={40} fontWeight="semibold" color="#fff">
                No Clue Found
              </Text>
            )}
          </div>
        );
    }
  };

  // useEffect(() => {
  //   currentClue?.id && setCurrentClueId(currentClue?.id);
  // }, [currentClue]);

  return (
    <>
      {clues.length === 0 ? (
        <div className={classes.noData}>No clue found</div>
      ) : (
        <div className={classes.wrapper}>
          {renderClueContent()}
          <ClueList
            handleSelectClue={handleSelectClue}
            currentClue={currentClueId}
            clues={clues}
            allFound={allFound}
          />
        </div>
      )}
    </>
  );
};

export default ClueListView;
