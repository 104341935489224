import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";
import cloneDeep from "lodash/cloneDeep";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Drawer from "src/components/AppDrawer";
import Button from "src/components/Button";
import Text from "src/components/Text";
import { DEFAULT_DATE_FORMAT } from "src/helpers/constants";
import { ANALYTICS_CONSTANTS } from "src/helpers/constants/analytics";
import {
  ROLE_CHECKING,
  ROLE_LEVEL,
  ROLE_NAME,
} from "src/helpers/constants/role";
import useUserProfile from "src/helpers/hooks/useUserProfile";
import { ACCESS_LEVELS } from "src/pages/Auth/AccessInfo";
import { useIndexData } from "src/pages/Watch/selectorData";
import { getUserProfile } from "src/store/actions/auth";
import { useStoreActions } from "src/store/hooks";
import { trackEvent } from "src/utils/googleAnalytics";
import { getAccessLevel } from "src/utils/role";
import { useWindowSize } from "src/utils/useWindowSize";
import classes from "./SubscriptionSettings.module.scss";
import SwitchToAppModal from "./SwitchToAppModal";
import UpdateSubscriptionModal from "./UpdateSubscriptionModal";

type Props = {
  wrapperClassName?: string;
  openSubscriptionModal: boolean;
  setOpenSubscriptionModal: (val: boolean) => void;
};

const updatedAccessLevels = cloneDeep(ACCESS_LEVELS);
const mobileRedirectLink = process.env.REACT_APP_MOBILE_APP_DEEP_LINK_URL;

updatedAccessLevels.forEach((item) => {
  if (item.type === ROLE_CHECKING.FREE_ACCESS) {
    item.list = [
      "TG$ News & Entertainment",
      "Winner Highlights",
      "TG$ Channel Highlights",
      "Games & Prizes Information",
      "User Generated Highlights",
      "TG$ Impact Investment Program",
    ];
  } else if (item.type === ROLE_CHECKING.FULL_ACCESS) {
    item.list = [
      "Subscription Plan Per Game",
      "Weekly Prizes Access",
      "Full Game Play Access",
      "Grand Prize Access",
      "Full Entertainment Content Access",
    ];
  }
});

const SubscriptionSettings: React.FC<Props> = ({
  wrapperClassName = "",
  openSubscriptionModal,
  setOpenSubscriptionModal,
}) => {
  const { width = 0 } = useWindowSize();
  const userProfile = useUserProfile();
  const { subscription } = useIndexData();
  const actions = useStoreActions({
    getUserProfile,
  });

  const [accessLevels, setAccessLevels] = useState(updatedAccessLevels);
  const [toggleMenu, setToggleMenu] = useState(false);
  const onClose = () => {
    actions.getUserProfile();
    setOpenSubscriptionModal(false);
  };

  const currentAccountLevel = getAccessLevel(
    get(userProfile, "user.role.name", ROLE_NAME.FREE_ACCESS)
  );

  // const subscriptionPrice = get(subscription, "plan.price");
  const subscriptionFrequency = get(subscription, "plan.type");
  const subscriptionDate = get(subscription, "valid_from");
  const isWebSubscription = get(subscription, "transaction_type") === "stripe";
  const isCancelled = get(subscription, "is_cancelled", 0);
  const subscriptionStatus = get(subscription, "status");
  const upcomingSubscription = get(userProfile, "upcoming_subscription", null);
  const upcomingPlan = get(upcomingSubscription, "plan", null);

  useEffect(() => {
    if (currentAccountLevel === ROLE_LEVEL.FULL_ACCESS) {
      const filteredAccessLevels = ACCESS_LEVELS.filter(
        (level) => level.name === ROLE_LEVEL.FULL_ACCESS
      );
      setAccessLevels(filteredAccessLevels);
    } else if (currentAccountLevel === ROLE_LEVEL.FREE_TRIAL) {
      const filteredAccessLevels = ACCESS_LEVELS.filter(
        (level) => level.name === ROLE_LEVEL.FREE_TRIAL
      );
      setAccessLevels(filteredAccessLevels);
    } else {
      const filteredAccessLevels = ACCESS_LEVELS.filter(
        (level) => level.name !== ROLE_LEVEL.FREE_TRIAL
      );
      setAccessLevels(filteredAccessLevels);
    }
  }, [currentAccountLevel]);

  const handleSubscription = () => {
    if (currentAccountLevel === ROLE_LEVEL.FREE_TRIAL) {
      trackEvent(
        ANALYTICS_CONSTANTS.category.sign_up,
        ANALYTICS_CONSTANTS.action.convert_free_to_full
      );
    }

    switch (true) {
      case !!subscription.id && !isWebSubscription && width <= 768: // Not subscribed via web, still logged in via mobile browser.
        return setToggleMenu(!toggleMenu);

      default:
        return setOpenSubscriptionModal(true);
    }
  };
  const getSubscriptionText = () => {
    switch (true) {
      case isCancelled && subscriptionStatus === "active":
        return (
          <Text size={20} color="#DEB771" className={classes.title}>
            <span className={classes.freeAccessText}>
              Subscription ending on:
            </span>{" "}
            {moment(subscription.valid_till).format(DEFAULT_DATE_FORMAT)}
          </Text>
        );
      case !isEmpty(upcomingSubscription) && !isEmpty(upcomingPlan):
        return (
          <Text size={20} color="#DEB771" className={classes.title}>
            <span className={classes.freeAccessText}>
              Subscription term changing to
            </span>{" "}
            {upcomingPlan.type === "monthly" ? "Monthly" : "Annual"}{" "}
            <span className="text-light">on</span>{" "}
            {moment(upcomingSubscription.valid_from).format(
              DEFAULT_DATE_FORMAT
            )}
          </Text>
        );
      default:
        return (
          <Text size={20} color="#DEB771" className={classes.title}>
            <span className={classes.freeAccessText}>Next billing date:</span>{" "}
            {moment(subscription.valid_till)
              .add(1, "day")
              .format(DEFAULT_DATE_FORMAT)}
          </Text>
        );
    }
  };

  return (
    <>
      {openSubscriptionModal &&
        (!!subscription.id && !isWebSubscription ? (
          <SwitchToAppModal
            isOpen={openSubscriptionModal && !isWebSubscription}
            onClose={onClose}
          />
        ) : (
          <UpdateSubscriptionModal
            isOpen={
              openSubscriptionModal && (!subscription.id || isWebSubscription)
            }
            onClose={onClose}
          />
        ))}
      <div className={cx(classes.wrapper, wrapperClassName)}>
        <Text color="#fff" size={24}>
          Subscription Settings
        </Text>
        <div className={classes.userLevel}>
          {currentAccountLevel === ROLE_LEVEL.FREE_TRIAL ? (
            <div className={classes.freeTrialInfo}>
              <Text
                size={20}
                fontFamily="impact"
                color="#DEB771"
                className={classes.title}
              >
                {accessLevels[0].title}
              </Text>
              <Text size={20} fontFamily="inter" color={"#fff"}>
                Experience full access by solving a limited amount of clues and
                exploring content created by other players and the Treasure
                Game$ team
              </Text>
              <div className={classes.list}>
                {accessLevels[0].list.map((info: string, index: number) => {
                  return (
                    <Text
                      color="#ffffff"
                      size={16}
                      fontFamily="inter"
                      key={index}
                    >
                      <FontAwesomeIcon color="#DEB771" icon={faCircleCheck} />
                      {info}
                    </Text>
                  );
                })}
              </div>
            </div>
          ) : (
            accessLevels.map((access, index) => {
              return (
                <div className={classes.card} key={index}>
                  <div>
                    {access.type === ROLE_CHECKING.FREE_ACCESS ||
                    currentAccountLevel === ROLE_LEVEL.FULL_ACCESS ? (
                      <>
                        <Text
                          size={20}
                          fontFamily="impact"
                          color="#DEB771"
                          className={classes.title}
                        >
                          {currentAccountLevel === ROLE_LEVEL.FULL_ACCESS
                            ? "Full Access"
                            : "Free Trial"}
                        </Text>
                        <Text
                          size={20}
                          color="#DEB771"
                          className={classes.title}
                        >
                          <span className={classes.freeAccessText}>
                            Subscription Term:
                          </span>{" "}
                          {subscriptionFrequency === "monthly"
                            ? "Monthly"
                            : "Annual"}
                        </Text>
                        <Text
                          size={20}
                          color="#DEB771"
                          className={classes.title}
                        >
                          <span className={classes.freeAccessText}>
                            Subscription started on:
                          </span>{" "}
                          {moment(subscriptionDate).format(DEFAULT_DATE_FORMAT)}
                        </Text>
                        {getSubscriptionText()}
                        {currentAccountLevel === ROLE_LEVEL.FULL_ACCESS && (
                          <>
                            {/* <Text
                              size={16}
                              fontFamily="inter"
                              color="#fff"
                              className={classes.title}
                            >
                              Paid{" "}
                              {subscriptionFrequency === "monthly"
                                ? "Monthly"
                                : "Annually"}{" "}
                              ${subscriptionPrice} (on{" "}
                              {moment(subscriptionDate).format(
                                DEFAULT_DATE_FORMAT
                              )}
                              )
                            </Text> */}
                            <Text
                              size={16}
                              fontFamily="inter"
                              color="#fff"
                              className={classes.levelIncludesText}
                            >
                              Your Level Access Includes:
                            </Text>
                          </>
                        )}
                      </>
                    ) : (
                      <Text size={20} color="#fff" className={classes.title}>
                        Upgrade to Full Access Level to enjoy these additional
                        features
                      </Text>
                    )}
                  </div>

                  <div className={classes.list}>
                    {access.list.map((info, index) => {
                      return (
                        <Text
                          color="#ffffff"
                          size={16}
                          fontFamily="inter"
                          key={index}
                        >
                          <FontAwesomeIcon
                            color="#DEB771"
                            icon={faCircleCheck}
                          />
                          {info}
                        </Text>
                      );
                    })}
                  </div>
                </div>
              );
            })
          )}
        </div>
        <div className={classes.changeSub}>
          <Button
            buttonText={
              currentAccountLevel === ROLE_LEVEL.FREE_TRIAL
                ? "Upgrade to Full Access"
                : "Change Subscription"
            }
            buttonColor="secondary"
            buttonClassName={classes.action}
            onClick={handleSubscription}
          />
        </div>
      </div>
      <Drawer
        isOpen={toggleMenu}
        onClose={() => setToggleMenu(false)}
        direction="bottom"
        overlay={false}
        drawerClassName={classes.confirmDrawer}
      >
        <Text
          color="#fff"
          fontFamily="inter"
          fontWeight="semibold"
          size={16}
          className="mb-2"
        >
          Treasure Games is better on the app
        </Text>
        <Text color="#fff" fontFamily="inter" size={14} className="mb-4">
          Continue updating your subscription settings in the Treasure Games
          app.
        </Text>
        <div className={classes.btnWrapper}>
          <Button
            buttonText="Switch to the App"
            buttonColor="secondary"
            buttonClassName={classes.confirmBtn}
            onClick={() => {
              window.open(mobileRedirectLink, "_self");
              setToggleMenu(false);
            }}
          />
        </div>
      </Drawer>
    </>
  );
};
export default SubscriptionSettings;
