import cn from "classnames";
import { useState } from "react";
import { toast } from "react-toastify";
import { ReactComponent as ThumbnailIcon } from "src/assets/icons/thumbnail-icon.svg";
import Upload from "src/components/Upload";
import { NEW_ID } from "src/helpers/constants";
import { ThumbnailData } from "src/pages/Watch/components/UploadModal/types";
import { getFileSize, readFileSrc } from "src/utils/file";
import classes from "./Thumbnail.module.scss";
interface Props {
  className?: string;
  thumbnails: ThumbnailData[];
  onThumbnailChanged?: (val: ThumbnailData) => void;
  activeThumbnail?: ThumbnailData;
  onCustomFileChanged?: (val: any) => void;
}

const Thumbnail = ({
  className,
  thumbnails,
  activeThumbnail,
  onThumbnailChanged,
  onCustomFileChanged,
}: Props) => {
  const [uploadedSrc, setUploadedSrc] = useState<string>("");
  const displayThumbnails = [
    ...(uploadedSrc
      ? [
          {
            id: NEW_ID,
            url: uploadedSrc,
          },
        ]
      : []),
    ...thumbnails,
  ];
  return (
    <div className={cn(classes.wrapper, className)}>
      <label>Thumbnail</label>
      <div className={classes.list}>
        {!uploadedSrc && (
          <Upload
            placeholderClassName={classes.uploadThumbnail}
            onChange={async (tFile) => {
              const tSize = tFile?.size || 0;
              const sizeAsGB = parseFloat(getFileSize(tSize, "GB"));
              if (sizeAsGB <= 5) {
                if (onCustomFileChanged) onCustomFileChanged(tFile);
                const src = await readFileSrc(tFile);
                setUploadedSrc(src as string);
                if (onThumbnailChanged)
                  onThumbnailChanged({
                    id: NEW_ID,
                    url: String(src),
                  });
              } else {
                toast.error(
                  "File size is bigger than maximum size, 5GB for image!"
                );
              }
            }}
            accept="image/png, image/gif, image/jpeg"
          >
            <>
              <span className={classes.thumbnailIcon}>
                <ThumbnailIcon />
              </span>
              <span>Upload Thumbnail</span>
            </>
          </Upload>
        )}
        {displayThumbnails.map(({ id, url }: ThumbnailData) => {
          return (
            <div
              className={cn(classes.thumbnailItem, {
                [classes.active]: activeThumbnail?.id == id,
              })}
              key={id}
              onClick={() => {
                if (onThumbnailChanged) onThumbnailChanged({ id, url });
              }}
            >
              <img src={url} alt={`thumbnail-${id}`} />
            </div>
          );
        })}
      </div>
      <span>Select or upload an image for the video's thumbnail preview.</span>
    </div>
  );
};

export default Thumbnail;
