import parse from "html-react-parser";
import { ReactComponent as CircleCheck } from "src/assets/icons/notification/circle-check.svg";
import classes from "./styles.module.scss";

type FollowUpInstructionsProps = {
  instructions: string | undefined;
};

const FollowUpInstructions = ({ instructions }: FollowUpInstructionsProps) => {
  return (
    <section className={classes.followUpContainer}>
      <div className={classes.iconContainer}>
        <CircleCheck />
      </div>
      <article className={classes.instructionsContainer}>
        {parse(instructions || "")}
      </article>
    </section>
  );
};

export default FollowUpInstructions;
