import React from "react";
import { Button } from "reactstrap";
import Text from "src/components/Text";
import InRangeImg from "src/pages/Play/Clue/Components/ClueDashboard/CluePurchase/assets/in-range-example1.png";
import NotInRangeImg from "src/pages/Play/Clue/Components/ClueDashboard/CluePurchase/assets/not-in-range-example1.png";
import { RangeFinder } from "src/types/clue";
import { TGS_BASE_URL } from "src/utils/constants";
import { useCluesData } from "src/utils/useData";
import CantPurchaseReason from "./CantPurchaseReason";
import DetailsBlock from "./DetailsBlock";
import classes from "./RangeFinderInfo.module.scss";

type Props = {
  handleSelectOption: (option: RangeFinder) => void;
  lampData: any;
};

const RangeFinderInfo: React.FC<Props> = ({ handleSelectOption }) => {
  const {
    rangeFinderBuyOptions: { data: options = [] },
  } = useCluesData();

  const rangeFinderOptions = options.filter(
    (option) => option.type === "range_finder"
  );

  const purchasePeriodInDays =
    rangeFinderOptions[0]?.purchase_period_in_days ?? 0;

  return (
    <div className={classes.wrapper}>
      <div className={classes.description}>
        <Text
          style={{ lineHeight: "normal" }}
          color="#fff"
          size={16}
          fontWeight="medium"
        >
          The Range Finder allows players to place a pin on a map to have it
          check whether that location is within the purchased distance from the
          Outer Zone. Each distance is made available at different dates and can
          be purchased once every {purchasePeriodInDays} days.{" "}
          <a href={`${TGS_BASE_URL}/a/wl-advantage-clues#range-finder`}>
            Learn more
          </a>
          .
        </Text>
      </div>
      <DetailsBlock title="Details" className="mb-5">
        <>
          <div className="how-it-works">
            <b>How It Works:</b>
            <ul>
              <li>Choose an available distance under Buy Now.</li>
              <li>Place a pin by clicking on the region map that appears.</li>
              <li>Proceed to Payment, and confirm.</li>
              <li>
                After placing the pin & successfully paying, your result will
                display.
              </li>
              <li>
                Purchased results for the Range Finder will be saved in your
                purchase history.
              </li>
            </ul>
          </div>
          <div className="distance-availability">
            <b>Distance Time Frame Availability:</b>
            <ul>
              {rangeFinderOptions.map((option: RangeFinder, index: number) => {
                return (
                  <li key={index}>
                    {option.range} Mile Range – Day {option?.available_on_day}
                  </li>
                );
              })}
            </ul>
          </div>
          <p>
            Once available,{" "}
            <strong>
              each distance can be purchased once every {purchasePeriodInDays}{" "}
              days
            </strong>
          </p>
          <div className="additional-information">
            <b>Additional Information:</b>
            <ul>
              <li>
                Range Finder works on both computers and mobile devices and can
                be used from anywhere.
              </li>
              <li>
                Must use the Range Finder at the time of purchase. Can use
                available distances as many times as you like.
              </li>
            </ul>
          </div>
        </>
      </DetailsBlock>
      <div className={classes.content}>
        <div className={classes.details}>
          <Text className="mb-3" size={18} color="#fff" fontWeight="bold">
            Examples
          </Text>
          <div className={classes.infoWrapper}>
            <Text className={classes.text} size={16}>
              Based upon the point on the map and the distance you selected, the
              Ranger Finder will let you know if the pin is within the purchased
              distance from the Lamp's Outer Zone.
            </Text>
            <div className={classes.info}>
              <div className={classes.example}>
                <Text size={14} color="#61B036" fontWeight="bold">
                  IN RANGE
                </Text>
                <img src={InRangeImg} />
              </div>
              <div className={classes.example}>
                <Text size={14} color="#DC4B4B" fontWeight="bold">
                  NOT IN RANGE
                </Text>
                <img src={NotInRangeImg} />
              </div>
            </div>
          </div>
        </div>
        <div className={classes.buyActions}>
          <Text className="mb-3" size={18} color="#fff" fontWeight="bold">
            Buy Now
          </Text>
          <div className={classes.actions}>
            {rangeFinderOptions.map((option: RangeFinder, index: number) => {
              return (
                <div key={option.id}>
                  <Button
                    onClick={() => handleSelectOption(option)}
                    className={classes.button}
                    key={index}
                    disabled={option.is_disabled || option.is_purchased}
                  >
                    <Text
                      color="#fff"
                      fontFamily="inter"
                      size={18}
                      fontWeight="bold"
                    >
                      {option.range} Miles for ${option.amount}
                    </Text>
                  </Button>
                  <CantPurchaseReason rangeItem={option} />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RangeFinderInfo;
