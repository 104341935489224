import find from "lodash/find";
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import { currencyFormatter, getCurrencyNumber } from "src/utils/number";

export const getRegionInfo = (regionData: any) => {
  const lampData = regionData?.lamp_data || [];
  const season1 = find(lampData, { season_id: 1 });
  const isSeason1Found =
    !!season1?.found_status && season1?.found_status === "found";
  const isSeason1Draft = !!season1?.status && season1?.status == "draft";
  const season1Date =
    !isSeason1Found && (!season1?.price || isSeason1Draft)
      ? season1?.custom_launch_date
      : "";
  const season2 = find(lampData, { season_id: 2 });
  const isSeason2Found =
    !!season2?.found_status && season2?.found_status === "found";
  const isSeason2Draft = !!season2?.status && season2?.status == "draft";
  const season2Date =
    !isSeason2Found && (!season2?.price || isSeason2Draft)
      ? season2?.custom_launch_date
      : "";

  const foundLamp =
    isSeason1Found && isSeason2Found
      ? 2
      : isSeason1Found || isSeason2Found
      ? 1
      : 0;
  const totalLamp = lampData.length;
  const isInactiveRegion =
    totalLamp === 0 ||
    (totalLamp === 1 && (isSeason1Draft || isSeason2Draft)) ||
    (totalLamp === 2 && isSeason1Draft && isSeason2Draft);
  const isAllLampFound = foundLamp === 2;
  const isFoundEqualTotal = totalLamp === foundLamp && totalLamp > 0;
  const isDisabledView =
    totalLamp === 2
      ? !!season1Date && !!season2Date
      : totalLamp === 1
      ? !!season1Date || !!season2Date
      : false;
  return {
    totalLamp,
    isInactiveRegion,
    isAllLampFound,
    foundLamp,
    isFoundEqualTotal,
    season1,
    season2,
    isDisabledView,
  };
};
export const getSeasonInfo = (seasonData: any) => {
  const customLaunchDate = seasonData?.custom_launch_date;
  const price =
    typeof seasonData?.price === "number" && seasonData?.price >= 0
      ? String(seasonData?.price)
      : "";
  const isFound = seasonData?.found_status === "found";
  const isScheduled = seasonData?.status === "scheduled";
  return {
    isFound,
    isNoLamp: isEmpty(seasonData),
    price: !isScheduled ? price : "",
    customLaunchDate: isScheduled ? customLaunchDate : "",
    isScheduled: isScheduled,
  };
};
export const getSeasonObj = (seasonData: any) => {
  return {
    defaultLabel:
      seasonData.isNoLamp &&
      !seasonData.isFound &&
      !seasonData.price &&
      !seasonData.customLaunchDate
        ? "Inactive"
        : "",
    status:
      !seasonData.isFound && !!seasonData.customLaunchDate
        ? "not_live"
        : !seasonData.isFound &&
          !seasonData.customLaunchDate &&
          !seasonData.price
        ? ""
        : "active",
    activateDate:
      !seasonData.isFound && !!seasonData.customLaunchDate
        ? `Activating ${moment(
            seasonData.customLaunchDate,
            "YYYY-MM-DD"
          ).format("MMM D, YYYY")}`
        : "",
    prize:
      !seasonData.isFound && !!seasonData.price
        ? getCurrencyNumber(seasonData.price)
        : "",
    lamp: seasonData.isFound
      ? {
          found: true,
          price: seasonData.price
            ? `${
                Number(seasonData.price) < 1000000
                  ? getCurrencyNumber(seasonData.price)
                  : `$${currencyFormatter(seasonData.price, 2)}`
              } Winner`
            : "",
        }
      : {},
  };
};
export const getNumberLampCount = (
  regions = [],
  currentSeason: number,
  regionList: any[]
) => {
  let lamps = 0;
  regions.forEach((region: any) => {
    const foundRegion = find(regionList, { slug: region.slug }) || {};
    lamps += (foundRegion.lamp_data || []).filter((obj: any) => {
      return (
        obj.found_status !== "found" &&
        obj.status !== "draft" &&
        (currentSeason === 1 ? obj.season_id === 1 : true)
      );
    }).length;
  });
  return lamps;
};
